<!--  -->
<template>
  <div class="agreement">
    <textarea rows="310" cols="240" style="border: 0;">
        黄埔人才网用户使用协议                  20210406-V1版
        黄埔人才网（以下简称“黄埔人才网”或“本平台”）是为用户提供用户信息管理、业务信息发布、浏览与查询、业务交易撮合与处理、订单查询与管理、业务费用代收代付等互联网信息服务和在线交易处理服务的网络平台。

        1 用户协议

          1.1 本条款需由您与黄埔人才网经营者共同缔造，在使用我们的服务之前，请先仔细阅读本《用户协议》。黄埔人才网是灵活(广州)网络科技有限公司(以下称灵活公司)旗下运营或向用户提供具体服务的相关主体的统称。“用户”是指使用黄埔人才网服务进行活动的个人及企业用户。
          。

          1.2 注册黄埔人才网手机应用完成后，通过手机获取验证码进入登陆界面。填写本人身份证信息并上传本人身份证及手持身份证拍照的照片。上传信息后，进入待审核状态，审核周期一般会在 24 小时内。审核通过后，会提示参加线上培训培训完成后，即可进入正常发单/接单。

          1.3 点击“登陆"按钮，即表示您已接受了以下条款和条件，本用户协议即对您与黄埔人才网、灵活(广州)网络科技有限公司有合同效力，应当共同遵守。

          1.4 以下这些条款和条件（以下简称“用户协议"）适用于您访问和使用我们的网站（以下简称“网站"）、服务、应用程序（定义见下文），以及在网站、服务、应用程序上或通过它们向您提供的所有信息、建议和/或服务。

        2·黄埔人才提供哪些服务

          2.1 发包方：可以在黄埔人才网享受发布业务需求信息、浏览与查询接包方信息业务订单查询与管理、委托平台代收代付等服务；

          2.2 接包方：可以在黄埔人才网享受筛选发包方，浏览查询发包方信息，自主接受需求订单，自主完成业务服务，委托平台代扣个税和委托第三方代开发票等服务。

        3 · 签约用户身份要求和承诺

          3.1 您首次登录时，即默认注册使用黄埔人才网，使用黄埔人才网服务需要您向黄埔人才网提交身份验证信息、银行账户等信息。

          3.2 发包方是自然人的，必须具有完全民事行为能力且具备相应资质才能发布业务需求信息。您承诺：您对所发布业务的真实性、安全性、合法性进行保证；如果您是代表法人实体签订本合约，您需提交资质证明，获得授权签订（并约束该法人实体）遵守本《用户协议》，并对所发布业务符合各项之规定负责。

          3.3 接包方必须年满 1 8 周岁具有完全民事行为能力和劳动能力，并具备相应资质才能申请成为接包方。接包方承诺：根据业务信息要求，将业务发包方发布的业务保质保量完成；并承诺在接受订单至完成业务期间，承担因业务行为所引发的一切可能责任（包括但不限于自身及他人人身安全或财产安全责任）。

          接包方申请成为业务服务方（不区分类型），建议参加发包方/黄埔人才网组织的相应的业务培训和业务管理。该类培训和管理将向接包方介绍规则与要求，以减少接包方可能造成的错误和损失。接包方自愿参加，不视为接包方与发包方/黄埔人才网形成管理或控制关系。接包方承诺不以黄埔人才网及相关运营商的名义完成业务，接包方所实施的服务内容系个人行为，非职务行为。接包方与本平台或与本平台关联公司之间不构成劳动和劳务关系。

        4 · 如何使用服务和应用程序

          4.1 应用程序允许用户在黄埔人才网上发布或接受业务需求信息。

          4.2 对于发包方而言，需登录黄埔人才网管理后台，通过身份验证和签约后选择发布业务需求区域，注明业务具体内容和要求。

          4.3 对于接包方而言，需注册"黄埔人才网接包方版"应用，通过身份认证，具备与其服务项目相匹配的服务技能。平台对接包方的服务技能和服务结果有权不定期进行考核。

          4.4 您已下载应用程序的移动设备（智能手机）上应安装 GPS 接收器。该接收器会测定您所在位置，并将您的位置信息上传到服务器，以便接包方或发包方了解您当前位置。接包方完全有权决定是否接受每个业务服务请求，也完全有权自行决定是否使用应用程序、接收通过应用程序产生的潜在客户。

          4.5 如果接包方接受请求，应用程序会通知发包方并提供接包方相应信息，包括其名称、联系方式、客户服务评价。此外，程序还允许接包方查看接包方当前可以服务的业务区域。

          4.6 发包方在平台的发单行为即视为要约，一经接包方接单，发包方和接包方即达成承揽合意，并适用本平台规则监督。

          4.7 黄埔人才网应做出合理的努力，以帮助发包方联系上接包方获取业务服务。不过，这仍然受制于发包方请求服务时接包方的数量和其状态。

          4.8 为了避免疑问，特澄清以下信息：黄埔人才网上包含多种业务接包方，发包方可在发布时自由选择所需服务类型。黄埔人才网并不直接参与发包方与接包方之间的业务交易，双方因业务履行发生争议的，应当根据合约相对性原则自行协商处理或寻求才得利调解协助。

          4.9 为了确保接包方安全完成业务，避免或减少接包方因发生包括但不限于交通事故等造成自身或他人损失，发包方可委托黄埔人才网经营者或灵活(广州)网络科技有限公司合作方）向保险公司办理商业保险业务，并支付相应保险费用。如因用户账户余额不足导致扣款失败无法及时购买保险的，后果由用户自行承担。

          4.10 平台可能会向您发送各种通知，该等通知或合同的签署发送至您的服务器即视为送达并具有法律效力。

        5 · 用户对应用程序或服务的正确使用和保证

          5.1 您保证向黄埔人才网提供的信息正确、完整。承认黄埔人才网在任何时候都有权验证您的信息，并有权在不提供任何理由的情况下拒绝向您提供服务或拒绝您使用应用程序。

          5.2 您只能使用授权途径访问服务。您有责任检查，以确保为设备下载正确的应用程序。如果您的移动设备不兼容或者为移动设备下载的应用程序版本有误，黄埔人才网概不承担责任。

          如果您正在不兼容或未授权的设备上使用服务或应用程序，黄埔人才网保留终止向您提供服务和拒绝您使用应用程序的权利。

          5.3 您使用应用程序或服务，即表示您还同意以下事项：

          5.3.1 您出于自己的个人用途使用服务或下载应用程序，并且不会转售给第三方；

          5.3.2 您不会授权其他人使用您的帐户；

          5.3.3 您不会将帐户转让或以其他方式转给任何其他个人或法人实体；

          5.3.4 您不会在没有恰当授权的情况下使用受其他人的任何权利约束的帐户；
          5.3.5 您不会将服务或应用程序用于非法目的，包括（但不限于）发送或存储任何非法资料或者用于欺诈目的；

          5.3.6 您不会利用服务或应用程序骚扰、妨碍他人或造成不便；

          5.3.7 您不会影响网络的正常运行；

          5.3.8 您无论如何都不会尝试危害服务或应用程序；

          5.3.9 未获得黄埔人才网的书面许可，您不会复制或分发应用程序或其他黄埔人才网内容；

          5.3.10您会妥善保管平台帐户名称和密码或者我们向您提供的用于访问服务和应用程序的任何身份识别信息，而不会泄露这些信息。该账户和密码应只限于您本人使用，通过该账户进行的任何操作包括但不限于接受任务事项等，均视为您本人接受，相关责任亦由您本人承担；

          5.3.11 在接受、完成任务事项的过程中获取的任何有关本平台或任何第三方的信息，包括但不限于发包方的名称、联系方式、地址等，均需严格保密，不得传播、泄露、披露，不得在为完成任务事项之外的目的自行或允许他人使用该类信息。若因您的故意或者过失，致使本平台或者任何第三方信息被传播、泄露、披露的，您应当承担给本平台造成的一切损失。本平台因此先行垫付或承担连带责任的，有权向您追偿；

          5.3.12 当我们提出合理请求时，您承诺会及时提供任何身份证明；

          5.3.13 您仅使用您获权使用的访问点或 3G 数据帐户(AP);

          5.3.14 您知道，当您通过短信请求业务服务（如果您所在辖区有此项服务）时需要支付标准信息费；

          5.3.15 您不会使用不兼容或未授权的设备使用服务或应用程序；

          5.3.16 您将遵守自己国家/地区以及您在使用应用程序或服务时所处国家/地区、省和/或市的所有适用法律。

          如果您违反以上任一规则，黄埔人才网保留立即终止向您提供服务和停止您使用应用程序的权限。
        6 ．信息服务费及付款规程

          6.1 黄埔人才网信息服务平台向您提供的服务付出了大量的成本，黄埔人才网有权单方面向您收取合理的服务费用。

          6.2 您使用黄埔人才网即表示您授权并同意黄埔人才网、灵活(广州)网络科技有限公司可实施下列权利：

          6.2.1 接包方授权黄埔人才网代表接包方向发包方收取业务服务费用，发包方同意上述行为；

          6.2.2 发包方和接包方同意业务服务费须通过黄埔人才账户中心结算，并授权灵活(广州)网络科技有限公司从各方的黄埔人才网的账户中心或注册服务时提供的银行帐户中，扣除或退回据实发生的或与其相关的业务服务费用（包括任何税费和服务费）；

          6.2.3 发包方与接包方委托黄埔人才网、灵活(广州)网络科技有限公司代理其进行业务服务费结转。黄埔人才网经营者不对双方交易行为真实性或瑕疵承担任何责任；

          6.2.4 用户授权黄埔人才网签订各类相关协议。

          6.3 用户在选择使用黄埔人才网并签约时，黄埔人才网保留以下权利：为了保证服务的可持续，用户始终有责任及时支付所有费用，并黄埔人才网向提供有效的银行帐户，用于支付所有费用。

          6.4 发包方可以在网站上或通过应用程序查看接包方所提供的业务服务的结算明细。

          6.5 接包方可在账户中心查看收益等资金交易明细，并进行提现操作。其中用户提现时会收取一定的手续费，该手续费与第三方支付平台的结算费率有关。

          6.6 所有用户在处理与使用应用程序和服务相关的付款或信贷（如适用）时，除了受到本《用户协议》的约束之外，还要受付款处理商及发卡行的条款、条件和隐私政策的约束。如果付款处理商发生任何错误，黄埔人才网概不负责。黄埔人才网将获取与您使用服务相关的特定交易明细，黄埔人才网在使用这些信息时将严格遵守其隐私政策和《Cookie 通知》。

        7 · 用户方的赔偿责任及范围

          7.1 您在接受本《用户协议》并使用应用程序或服务时，即表示同意：对于因以下事项产生的或与以下事项相关的任何及所有索赔、费用、赔偿、损失、债务和开销（包括律师费和诉讼费），您将全额自行承担责任，并有义务向黄埔人才网、附属公司、合作方、许可方、关联方及其所有管理人员、董事、其他用户、员工、律师和代理人如实陈述事实，在必要时配合作为证人出庭，使其免受不必要的损失。

          7.1.1 您触犯或违反本《用户协议》中的任何条款或任何适用的法律法规（无论此处是否提及）造成自、他损失；

          7.1.2 您触犯任何第三方（包括通过应用程序安排的接包方）的任何权利，造成自、他损失；

          7.1.3 您使用或滥用应用程序或服务造成自、他损失。

        8 · 网络信息平台的责任约定及免责事项

          8.1 在网站、服务和应用程序上或通过它们向您提供的信息、推荐和/ 或服务仅用作一般参考信息，并不构成实质性建议。黄埔人才网将在合理的范围内保证网站、应用程序及其信息内容正确、最新，但无法保证网站和/或应用程序（的内容）没有错误、缺陷、恶意软件和病毒，也无法保证网站和/或应用程序正确、最新、无误。

          8.2 对于因使用（或无法使用）网站或应用程序导致的任何损害，包括但不限于由恶意软件、病毒、信息的任何不正确或不完整、网站或应用程序导致的损害等，除非此类损害是由黄埔人才网方面任何蓄意不当行为或重大疏忽造成的，否则黄埔人才网概不负责。

          此外，对于因使用（或无法使用）与网站或应用程序的电子通信手段导致的任何损害，包括（但不限于）因电子通信传达失败或延时、第三方或用于电子通信的计算机程序对电子通信的拦截或操纵，以及病毒传输导致的损害，黄埔人才网也概不负责。

          在不涉及上述约定免责事由的情况下，如有法律强制性规定网络平台承担责任的，您同意接受黄埔人才网承担的全部责任不超过 1000 元人民币，或（如适用）等于您向接包方支付业务服务费用/或接包方获得业务服务费用时所需的货币金额。

          发包方通过应用程序或服务请求的业务服务质量由最终向您提供此类业务服务的接包方负责。黄埔人才网在任何情况下都不承担因与接包方提供的业务服务或者接包方的任何行动、举措、行为、举动和/或疏忽相关的以及/或者由此产生的责任。因此，任何跟接包方提供的业务服务相关投诉应提交给接包方。黄埔人才网可对您的评价进行反馈，但不承诺对业务纠纷承担解决义务。

        9 · 许可授予和限制

          9.1 本《用户协议》适用以下定义：

          9.1.1 "内容"指所有呈现或显示的内容，包括（但不限于）徽标、图标、商标文本、图文、图形、照片、图像、动态影响、声音、图表、音乐、文件（不包括应用程序）、观点、评论、意见、图片、链接、问题、建议、信息或其他资料。

          9.1.2“黄埔人才网内容"指黄埔人才网、其附属公司、关联公司或许可方拥有或使用的并通过网站、服务或应程序提供的内容，包括第三方许可的任何内容，但不包括用户内容。"用户"指访问或使用服务或应用程序的人。

          9.1.3“用户内容"指用户张贴、上传、发布、提交或传输并在网站上或者通过服务或应用程序提供的内容。

          9.1.4“共有内容"是黄埔人才网内容和用户内容的统称。

          9.2 在您遵守本《用户协议》的前提下，黄埔人才网向您授予有限的、非排他性的、不可转让的许可：

          i.用于查看、下载和打印任何黄埔人才网内容，并仅供非商业性的个人用途。

          ii.用于查看您有权访问的任何用户内容，并仅供非商业性的个人用途您无权再许可本节授予您的许可权利。

          9.3 除了本《用户协议》中明确许可之外，您不得使用、复制、改编、修改、创建衍生作品、分发、许可、销售、转让、公开展示、公开执行、再现、传播、流式传播、广播或以其他方式利用网站、服务、应用程序或共有内容。如事先未征得黄埔人才网的书面同意，不得再使用任何共有内容。除了本《用户协议》明确授予的许可和权利之外，黄埔人才网未暗示或以其他方式向您授予黄埔人才网在或其许可方拥有或控制的任何知识产权下的任何许可或权利。

          9.4 用户授予信息平台的许可，黄埔人才网可自行决定使用，包括但不限于允许用户在网站上或者通过服务或应用程序张贴、上传、发布、提交或传输用户内容。
          用户内容将视为非机密、非专有的内容。相应地，黄埔人才网应具有免版税的非排他性使用权利，可以在世界任何地方使用、复制和分发任何用户内容，并可透露给第三方（以下简称“许可授予"）。

          9.5 您承认，黄埔人才网仅充当分发用户内容的被动中转渠道，并不会因“用户内容”的内容或准确性而向您或任何第三方承担责任。黄埔人才网会不间断地监控您发布的用户内容或者缓和用户之间的冲突，但黄埔人才网不承担此类义务。在不限制上述内容的情况下，您承认并同意，用户内容中表达的或包含任何不利的评论、观点、意见、建议和其他信息不代表黄埔人才网。

          9.6 您应自己全力承担使用用户内容的一切风险。您承诺并保证，您发布或传输的任何用户内容都是自己的原创内容，没有抄袭任何第三方的作品，也没有以其他方式侵犯任何第三方的知识产权、隐私权或人格权，并且不包含任何诽谤或毁谤言语。此外，您承诺并保证，您有能力授予本节所述的许可。

          9.7 您同意，因您张贴或传输的任何用户内容或者您对网站、服务或应用程序的其他使用方式导致黄埔人才网、其合作方、附属公司、关联方、第三方或者许可方产生或蒙受所有费用、开销、赔偿、损失和债务，您应该对黄埔人才网、合作方、附属公司、关联方、第三方和许可方予以赔偿，使其免受损失。

          如果黄埔人才网认为您张贴或传输的任何用户内容违反本《用户协议》（包括侵犯或可能侵犯第三方知识产权、隐私权或人格权的资料）或者以其他方式不被黄埔人才网接受，黄埔人才网保留自行决定阻止或删除这些内容（全部或部分）的权利

          9.8 您同意，如果您发现有任何用户内容违反本《用户协议》，应及时以书
          面形式通知黄埔人才网。您同意向黄埔人才网提供充足的信息，以便黄埔人才网核实此类用户内容是否违反本《用户协议》。黄埔人才网同意，做出善意的努力来调查此类投诉，并采取黄埔人才网自行决定的措施。不过，黄埔人才网不会保证或承诺，其会阻止或删除此类用户内容。

          9.9 在您遵守本《用户协议》的前提下，黄埔人才网向您授予有限的、非排他性的、不可转让的许可，以将一份应用程序副本下载并安装到您拥有或控制的单台移动设备上，并仅出于您自己的个人用途运行此应用程序副本。

          9.10 您不得：

          9.10.1 以任何方式许可、再许可、出售、转售、转让、分配、分发服务或应用程序，或以其他方式进行商业开发或提供给任何第三方；

          9.10.2 修改服务、应用程序，或者据此创建衍生产品;

          9.10.3 创建指向服务的互联网“链接"，或在任何其他服务器或基于无线或互联网的设备上“设计"或"镜像"任何应用程序；

          9.10.4 反向工程或访问应用程序以设计或构建竞争产品或服务；

          9.10.5 使用类似于服务或应用程序的设想、特点、功能或图形，来设计、构建产品，或抄袭服务、应用程序的任何设想、特点、功能或图形，或启动自动程序或脚本，包括（但不限于）任何可能每秒发送多个服务器请求、过度加重服务、应用程序负担或妨碍其工作和/或性能的程序。

          9.11 您不得：

          9.11.1发送垃圾邮件或者以其他形式违反适用法律的重复或不受欢迎的邮件；

          9.11.2 发送或存储侵权、淫秽、威胁、诽谤或者其他非法或侵权资料，包括危害儿童或触犯第三方隐私权的资料；

          9.11.3 发送或存储包含软件病毒、蠕虫、木马或其他有害的计算机代码、文件、脚本、代理或程序的资料；

          9.11.4 阻挠或扰乱网站、应用程序、服务或其所含数据的完整性;

          9.11.5 尝试未经授权地访问网站、应用程序、服务或其相关系统或网络。

          9.12 上述违法违规的行为由您自行承担责任。在法律允许的最大范围内，黄埔人才网将有权调查并起诉任何上述违法违规行为。黄埔人才网可参与并协助执法部门起诉违反本《用户协议》的用户。您承认黄埔人才网，没有义务监控您访问或使用网站、服务、应用程序或共有内容，也没有义务审查或编辑任何共有内容，但黄埔人才网有权出于维护运营网站、应用程序和服务的目的采取上述行动，以确保您遵守本《用户协议》，或者遵守适用法律或法院、行政机构或其他政府机关的指令或要求。如果黄埔人才网自行决定任何共有内容违反本《用户协议》或以其他方式危害网站服务或应用程序，黄埔人才网保留在不另行通知的情况下随时删除或禁用对这些共有内容的访问权限的权利。

        10 ．版权规则
          黄埔人才网遵守版权法，并希望用户也遵守版权法。如果用户或其他帐户持有者（反复）侵犯或可能（反复）侵犯版权所有者的权利，黄埔人才网的政策是在合适的情况下终止用户或其他帐户持有者。

        11 ．知识产权归属

          11.1 黄埔人才网自己（及其许可方，如适用）拥有网站、应用程序、服务以及您或任何其他第三方提供的、跟网站、应用程序或服务相关的任何建议、设想、改建需求、反馈、推荐或其他信息的所有权利、所有权和权益（包括所有相关的知识产权）均归属于黄埔人才网经营者。本《用户协议》不构成向您销售或转让网站、
          应用程序或服务中的任何所有权或黄埔人才网拥有的任何知识产权。

          11.2 黄埔人才网的名称、徽标以及与应用程序、服务关联的产品名称都是黄埔人才网、其附属公司或第三方的商标，本《用户协议》并不授予您使用它们的权利或许可。

        12·从 从 APPSTORE 获得的应用程序

          对于通过 AppleAppStore 访问或从其下载的任何应用程序（以下简称"从Appstore 获得的应用程序"），您只能按以下所述使用从 Appstore 获得的应用程序：

          i.在运行 ios（Apple 的专有操作系统软件）的 Apple 品牌产品上使用这些应用程序；

          ii.在使用这些应用程序时遵守《AppleAppstore 服务条款》规定的"用法规则"。黄埔人才网保留本《用户协议》中未明示授予您的所有应用程序权利。
        
        13· 第三方交互

          在您使用网站、应用程序和服务期间，黄埔人才网可能会不时地提供由第三方拥有并控制的网站链接，以便您跟第三方沟通，向其购买产品或服务，参加其提供的促销活动。这些链接会带领您离开网站、应用程序和服务，并且不在黄埔人才网的控制范围之内。

          第三方服务提供商、广告商或赞助商会通过网站上的链接或者通过应用程序或服务展示商品和/或服务。您在使用网站、应用程序和服务期间，可能会跟这些第三方沟通，向其购买产品和/或服务，或者参加其提供的促销活动。这些链接会带领您离开网站、应用程序和服务，并且不在黄埔人才网的控制范围之内。这些链接会使您登录到的网站各自订立了条款、条件和隐私政策。黄埔人才网不会对这些网站的内容和活动负责，也不会承担任何义务。因此，您应自己全力承担浏览或访问这些网站的一切风险。

          请注意，这些其他网站可能会向用户发送自己的 cookie、收集数据或请求个人信息。因此，建议您在使用这些网站之前，先查看它们的使用条款或隐私政策。

        14 · 协议的期限和终止

          14.1 您有权随时通过永久性删除智能手机上安装的应用程序来终止合约，这样将禁止您使用应用程序和服务。您可以随时按照黄埔人才网（及其许可方，如适用）网站上的说明注销用户帐户。

          14.2 如果您做出以下行为，黄埔人才网有权随时终止合约并立即生效（即禁止您使用应用程序和服务）·

          14.2.1 您触犯或违反本《用户协议》中的任何条款；

          14.2.2 黄埔人才网认为，黄埔人才网没有义务因您滥用应用程序或服务提前通知合约终止。合约终止之后，黄埔人才网将按照本《用户协议》给出相关通知。

          14.3 合约终止时，您应向本平台指定人员办理相应的相关事务交接并退还相关设备、文件等手续；未按规定办理手续的，黄埔人才网有权冻结您的账户和资金。
        
        15· 法律责任

          15.1 您违反本协议及相关法律规定的义务的，您应当向本平台承担违约责任或赔偿责任。

          15.2 您保证签署和履行本协议，不违反您与其他主体之间的约定或您负有的其他义务，不侵犯任何第三人的合法权益，否则由此造成本平台损失的，您需承担赔偿责任。

        16· 适用法律和争议处理

          16.1 本条款的订立、执行和解释及争议的解决均应适用中华人民共和国法律。

          16.2 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。

          16.3 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。

          16.4 如双方就本条款内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向广州市黄埔区人民法院提起诉讼。

        17 · 其他事项

          17.1 黄埔人才网保留自行决定随时修改或替换本《用户协议》的任何内容，或者更改、暂停或中断服务或应用程序（包括但不限于任何功能、数据库或内容的可用性）的权利。届时，黄埔人才网只需在网站上张贴通告，或者通过服务、应用程序或电子邮件发送通知。黄埔人才网也可以对某些功能和服务施加限制，或者限制您访问部分或全部服务，恕不另行通知，也不承担任何责任。

          17.2 黄埔人才网经营者有权在公告告知的前提下，将本协议中的权利和义务一并转让给黄埔人才网经营者的关联公司或指定的公司，具体受让的关联公司或指定的公司以本平台公告为准，协议转让一经本平台公告后 7 日即生效。


      </textarea>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  },
  created() {

  }
}
</script>

<style lang="less" scoped>
.agreement {
  position: relative;
  background: #ffffff;
}
</style>
